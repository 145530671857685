import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Row,
  Col,
  Spinner,
  Button
} from "reactstrap";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { getFiles } from "src/slices/FileManager/thunks";
import { RootState } from '../../index';
import { setFilterFiles, setOriginalFiles, setSelectedFile } from "src/slices/FileManager/reducer";
import { getFileIcon } from "src/util/files";
import { isoToShortDate } from "src/util/date";

const Archivos = (prop: any) => {

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch( getFiles() );
  }, [])
  const { files, isLoading } = useSelector((state: RootState) => state.fileManager);
  const [filtro, setFiltro] = useState("")

  const buscar = (event: any) => {
    setFiltro(event.target.value)
    filtrarDocs(event.target.value)
  }

  const filtrarDocs = (filtro: string) => {
    if(filtro !== '') {
      dispatch(setFilterFiles({filtro}))
    } else {
      dispatch(setOriginalFiles())
    }
  }


  return (
    <React.Fragment>
    {
      isLoading 
      ? <Spinner
          className="spinner-border text-primary m-1 m-auto"
          role="status"
        ></Spinner>
      :<>
        <div>
          <Row className="mb-3">
            <Col lg={4} sm={6}>
              <div className="search-box mb-2">
                <div className="position-relative">
                  <input 
                    type="text" 
                    className="form-control bg-light border-light rounded" 
                    placeholder="Buscar..." 
                    value = { filtro }
                    onChange = { buscar }
                  />
                  <i className="uil uil-search search-icon"></i>
                </div>
              </div>
            </Col>
            <Col lg={8} sm={6}>
              <div className="mt-4 mt-sm-0 d-flex align-items-center justify-content-sm-end">
                <div className="mb-2 me-2">
                  <Link to="/repositorio/subir-documento" className="btn btn-primary">
                    Subir documento
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <hr className="mt-2" />

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Fecha de emisión</th>
                  <th scope="col">Fecha de incorporación</th>
                  <th scope="col">Tipo</th>
                </tr>
              </thead>
              <tbody>
                {files.map((documento) => (
                  <tr key={documento.id}>
                    <td>
                      <Link to="/repositorio/ver-documento" className="text-dark fw-medium" onClick={() => dispatch( setSelectedFile( {id: documento.id}) ) }>
                        <i className={ getFileIcon(documento.mime_type) }></i> {documento.nombre}
                      </Link>
                    </td>
                    <td>{(documento.fecha_emision) ? isoToShortDate(documento.fecha_emision) : 'No tiene'}</td>
                    <td>{isoToShortDate(documento.fecha_incorporacion)}</td>
                    <td>{documento.tipo}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    }
      
    </React.Fragment>
  );
};

export default Archivos;
